import { type ReactNode } from 'react';

import { type Block, BlockType } from '@lp-lib/game';

import {
  JeopardyActivityEditor,
  jeopardyGenAIAction,
} from './Activities/JeopardyActivityEditor';
import {
  TeamIntroActivityEditor,
  teamIntroGenAIAction,
} from './Activities/TeamIntroActivityEditor';
import {
  WinnerCelebrationActivityEditor,
  winnerCelebrationGenAIAction,
} from './Activities/WinnerCelebrationActivityEditor';
import { type ActivityEditorProps } from './types';

export function ActivityBlockEditor(
  props: ActivityEditorProps<Block> & {
    unsupported?: () => ReactNode;
  }
) {
  const { block } = props;
  // The editor can be resolved with the multiple fields.
  // For example, the block type and mode (scenario).
  switch (block.type) {
    case BlockType.TITLE_V2:
      return <TeamIntroActivityEditor {...props} block={block} />;
    case BlockType.JEOPARDY:
      return <JeopardyActivityEditor {...props} block={block} />;
    case BlockType.SPOTLIGHT:
      return <WinnerCelebrationActivityEditor {...props} block={block} />;
    default:
      return props.unsupported ? props.unsupported() : null;
  }
}

export function activityGenAIAction(
  packId: string,
  block: Block,
  prompt: string,
  signal?: AbortSignal | null,
  unsupported = () => Promise.resolve()
) {
  switch (block.type) {
    case BlockType.TITLE_V2:
      return teamIntroGenAIAction(packId, block, prompt, signal);
    case BlockType.JEOPARDY:
      return jeopardyGenAIAction(packId, block, prompt, signal);
    case BlockType.SPOTLIGHT:
      return winnerCelebrationGenAIAction(packId, block, prompt, signal);
    default:
      return unsupported();
  }
}
